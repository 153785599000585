<template>
  <SpinningModal v-if='showLoadingModal'/>
  <PcaEquityCostEditModal v-if='showEditModal'
    @edit-complete='editComplete'
    :closeEditModal='closeEditModal'
    :editCost='editCost'
    :categories='categories'
    :subsections='subsections'
  />
  <div class='sl-mb-10px sl-flex-justify-content-space-btwn' style='color: #005199'>
    <span class='sl-font-size-16px sl-bold-text'>PCA Equity Cost Database</span>
    <div>
      <span>Current Version: {{ version }}</span>
      <a v-if='userIsAdmin' href='/pca_cost_factors' class='sl-simple-outline-btn sl-ml-10px sl-tooltip'>
        <span class='sl-tooltiptext'>Admin Only</span>
        Modify Database
      </a>
    </div>
  </div>

  <form @submit.prevent='searchByButton'>
    <div class='sl-flex-wrap'>
      <!-- Country -->
      <div class='sl-flex-column sl-mtb-10px sl-mr-20px'>
        <label class='sl-mb-5px'>Country&nbsp*</label>
        <select @change='fetchSearchOptions($event)' v-model='selectedCountry' id='country'
                class='sl-w-200px sl-h-32px sl-border-1px-medium-charcoal'>
          <option :value=null selected style='color: #9F9EA4;'>Choose a Selection</option>
          <option v-for='country in countryOptions'>{{ country }}</option>
        </select>
      </div>

      <!-- State -->
      <div class='sl-flex-column sl-mtb-10px sl-mr-20px'>
        <label class='sl-mb-5px' :class="{'disabled-font':!selectedCountry}">State&nbsp*</label>
        <select @change='fetchSearchOptions($event)' v-model='selectedState' id='state'
                class='sl-w-200px sl-h-32px sl-border-1px-medium-charcoal'
                :disabled='!selectedCountry'
                required>
          <option :value=null selected style='color: #9F9EA4;'>Choose a Selection</option>
          <option v-for='state in stateOptions'>{{ state }}</option>
        </select>
      </div>

      <!-- City -->
      <div class='sl-flex-column sl-mtb-10px sl-mr-20px'>
        <label class='sl-mb-5px' :class="{'disabled-font':!selectedState}">City&nbsp*</label>
        <select @change='fetchSearchOptions($event)' v-model='selectedCity' id='city'
                class='sl-w-200px sl-h-32px sl-border-1px-medium-charcoal'
                :disabled='!selectedState'
                required>
          <option :value=null selected style='color: #9F9EA4;'>Choose a Selection</option>
          <option v-for='city in cityOptions'>{{ city }}</option>
        </select>
      </div>

      <!-- Section -->
      <div class='sl-flex-column sl-mtb-10px sl-mr-20px'>
        <label class='sl-mb-5px' :class="{'disabled-font':!enableSearchBtn()}">Section</label>
        <select @change='fetchSearchOptions($event)' v-model='selectedSection' id='section'
                class='sl-w-200px sl-h-32px sl-border-1px-medium-charcoal'
                :disabled='!enableSearchBtn()'>
          <option :value=null selected style='color: #9F9EA4;'>Choose a Selection</option>
          <option v-for='section in sections' :value=section.section_reference>{{ `${section.section_reference} - ${section.header_string}` }}</option>
        </select>
      </div>

      <!-- Subsection -->
      <div class='sl-flex-column sl-mtb-10px sl-mr-20px'>
        <label class='sl-mb-5px' :class="{'disabled-font':!enableSearchBtn()}">Subsection</label>
        <select @change='fetchSearchOptions($event)' v-model='selectedSubsection' id='subsection'
                class='sl-w-200px sl-h-32px sl-border-1px-medium-charcoal'
                :disabled='!enableSearchBtn()'>
          <option :value=null selected style='color: #9F9EA4;'>Choose a Selection</option>
          <option v-for='section in subsections' :value=section.section_reference >{{ `${section.section_reference} - ${section.header_string}` }}</option>
        </select>
      </div>

      <!-- Repair Component -->
      <div class='sl-flex-only'>
        <div class='sl-flex-column sl-mtb-10px sl-mr-20px'>
          <label class='sl-mb-5px' :class="{'disabled-font':!enableSearchBtn()}">Repair Component</label>
          <select @change='fetchSearchOptions($event)' v-model='selectedComponent' id='component'
                  class='sl-w-200px sl-h-32px sl-border-1px-medium-charcoal'
                  :disabled='!enableSearchBtn()'>
            <option :value=null selected style='color: #9F9EA4;'>Choose a Selection</option>
            <option v-for='component in components' :value=component>{{ component }}</option>
          </select>
        </div>
        <button type='submit' :class="{'sl-simple-outline-btn-disabled':!enableSearchBtn()}"
                class='sl-simple-outline-btn sl-h-28px sl-w-85px' style='margin-top: 33px'>
                Search
        </button>
      </div>

    </div>
  </form>
  <br>
  <table class='sl-mt-20px sl-w-100per sl-border-b-2px' style='border-collapse: collapse;'>
    <thead>
      <tr class='sl-border-tb-2px-primary-blue sl-h-45px'>
        <td class='sl-w-300px sl-pl-10px sl-bold-text sl-border-r-1px-medium-charcoal'>Repair Component</td>
        <td class='sl-w-100px sl-text-align-center sl-bold-text' style='padding: 0'>
          <div class='sl-text-align-right'>Adjustm</div>
          <div class='sl-border-t-1px'>Low</div>
        </td>
        <td class='sl-w-100px sl-text-align-center sl-bold-text' style='padding: 0'>
          <div class='sl-text-align-left'>ent Costs</div>
          <div  class='sl-border-t-1px'>High</div>
        </td>
        <td class='sl-w-100px sl-text-align-center sl-bold-text'>EUL</td>
        <td class='sl-w-100px sl-text-align-center sl-bold-text'>Unit</td>
        <td class='sl-bold-text'>Comments</td>
      </tr>
    </thead>
    <tbody v-for='section in costsBySection'>
      <tr class='sl-bold-text' style='background-color: #E5EEF5'>
        <td class='sl-pl-10px sl-h-32px'>{{ `${section.section_name} ${section.section_reference}` }}</td>
        <td></td><td></td><td></td><td></td>
        <td class='sl-flex-justify-content-end sl-flex-align-items-center sl-h-32px sl-pr-10px' style='color: #005199'>
          {{ section.parent_section_name }}
        </td>
      </tr>
      <tr v-for='cost in section.costs' class='sl-table__row--striped-odd sl-h-32px'>
        <td class='sl-w-300px sl-pl-10px sl-border-r-1px-medium-charcoal sl-ptb-10px'>
          <a v-if='userIsAdmin' @click='openEditModal(cost)' class='sl-cursor-pointer'>{{ cost.repair_component }}</a>
          <span v-else>{{ cost.repair_component }}</span>
        </td>
        <td class='sl-w-100px sl-text-align-center'>{{ cost.adjustment_cost_low }}</td>
        <td class='sl-w-100px sl-text-align-center'>{{ cost.adjustment_cost_high }}</td>
        <td class='sl-w-100px sl-text-align-center'>{{ cost.eul }}</td>
        <td class='sl-w-100px sl-text-align-center'>{{ cost.unit }}</td>
        <td class=' sl-ptb-10px'>{{ cost.comments }}</td>
      </tr>
    </tbody>
  </table>
  <div v-if='!costsBySection.length'
       class='sl-w-100per sl-border-b-1px sl-font-size-14px
              sl-h-106px sl-flex-justify-content-and-align-items-center'>
    <span>{{ searchResultText }}</span>
  </div>
  <Paginate v-if='numberOfPages && currentPage'
    :numberOfPages='numberOfPages'
    :currentPage='currentPage'
    @change-page='changePage'
  />
</template>
<script>
import axios from 'axios';
import SpinningModal from '../../components/SpinningModal.vue';
import Paginate from '../../components/Paginate.vue';
import PcaEquityCostEditModal from './PcaEquityCostEditModal.vue';

export default {
  components: {
    SpinningModal,
    Paginate,
    PcaEquityCostEditModal,
  },

  data() {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      version: '',
      countryOptions: [],
      stateOptions: [],
      cityOptions: [],
      sections: [],
      subsections: [],
      categories: [],
      costsBySection: [],
      selectedCountry: urlParams.get('country') || 'United States',
      selectedState: urlParams.get('state'),
      selectedCity: urlParams.get('city'),
      selectedSection: urlParams.get('section'),
      selectedSubsection: urlParams.get('subsection'),
      selectedComponent: urlParams.get('component'),
      currentPage: urlParams.get('page'),
      showLoadingModal: false,
      showEditModal: false,
      editCost: null,
      pageNumbers: [],
      numberOfPages: null,
      userIsAdmin: null,
      searchResultText: null,
    };
  },

  async created() {
    this.fetchSearchOptions();
    this.updateUrl();
    if (this.selectedCity) {
      this.search();
    }
    this.updateSearchResultText();
  },

  methods: {
    // Get options for the select inputs
    async fetchSearchOptions(event) {
      this.clearParams(event);
      await axios.get('pca_equity_costs/vue_index', {
        params: {
          country: this.selectedCountry,
          state: this.selectedState,
          city: this.selectedCity,
          section: this.selectedSection,
          subsection: this.selectedSubsection,
          component: this.selectedComponent,
        },
      })
        .then((response) => {
          this.version = response.data.version;
          this.countryOptions = response.data.countryOptions;
          this.stateOptions = response.data.stateOptions;
          this.cityOptions = response.data.cityOptions;
          this.sections = response.data.sections;
          this.subsections = response.data.subsections;
          this.categories = response.data.categories;
          this.components = response.data.components;
          this.userIsAdmin = response.data.userIsAdmin;
        })
        .catch(() => {
          alert('Something went wrong. Please reach out to admin@sitelynx.net for support.');
        });
    },

    // Search for costs
    async search() {
      this.updateUrl();
      this.showLoadingModal = true;
      await axios.get('pca_equity_costs/vue_search', {
        params: {
          country: this.selectedCountry,
          state: this.selectedState,
          city: this.selectedCity,
          section: this.selectedSection,
          subsection: this.selectedSubsection,
          component: this.selectedComponent,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.costsBySection = response.data.costs;
          this.pageNumbers = response.data.pageNumbers;
          this.numberOfPages = response.data.numberOfPages;
          this.updateSearchResultText();
          setTimeout(() => { this.showLoadingModal = false; }, 500);
        })
        .catch(() => {
          this.showLoadingModal = false;
          alert('Something went wrong. Please reach out to admin@sitelynx.net for support.');
        });
    },

    // When user hits the search button, set currentPage to 1, then make search request
    searchByButton() {
      this.currentPage = 1;
      this.search();
    },

    // Update url params when search request is made
    updateUrl() {
      const params = new URLSearchParams();
      if (this.selectedCountry) {
        params.set('country', this.selectedCountry);
      }
      if (this.selectedState) {
        params.set('state', this.selectedState);
      }
      if (this.selectedCity) {
        params.set('city', this.selectedCity);
      }
      if (this.selectedSection) {
        params.set('section', this.selectedSection);
      }
      if (this.selectedSubsection) {
        params.set('subsection', this.selectedSubsection);
      }
      if (this.selectedComponent) {
        params.set('component', this.selectedComponent);
      }
      if (this.currentPage) {
        params.set('page', this.currentPage);
      }
      history.replaceState(null, null, `?${params.toString()}`); // eslint-disable-line no-restricted-globals
    },

    // Clear url params if necessary after search request is made
    clearParams(event) {
      if (event?.target?.id === 'country') {
        this.selectedState = null;
        this.selectedCity = null;
      }
      if (event?.target?.id === 'state') {
        this.selectedCity = null;
      }
      if (event?.target?.id === 'section') {
        this.selectedSubsection = null;
        this.selectedComponent = null;
      }
      if (event?.target?.id === 'subsection') {
        this.selectedComponent = null;
      }
      if (event?.target?.id === 'category') {
        this.selectedComponent = null;
      }
    },

    // When user hits pagination page link, set currentPage to the number the user selected,
    // then make search request
    changePage(pageNumber) {
      this.currentPage = pageNumber;
      this.search();
    },

    // Return true if country, state, and city are selected, else return false
    enableSearchBtn() {
      return this.selectedCountry && this.selectedState && this.selectedCity;
    },

    // Update the text that shows when there are no results to display
    updateSearchResultText() {
      if (!this.costsBySection.length && this.selectedCountry && this.selectedState && this.selectedCity) {
        this.searchResultText = 'There are no costs for the selection.';
      } else if (!this.costsBySection.length) {
        this.searchResultText = 'Select a country, state, and city to find costs.';
      }
    },

    openEditModal(cost) {
      this.editCost = {
        id: cost.id,
        repair_component: cost.repair_component,
        section_reference: cost.section_reference,
        notes: cost.notes,
        eul: cost.eul,
        cost_low: cost.cost_low,
        cost_high: cost.cost_high,
        adjustment_category: cost.adjustment_category,
        comments: cost.comments,
      };
      this.showEditModal = true;
    },

    closeEditModal() {
      this.showEditModal = false;
    },

    editComplete() {
      this.showEditModal = false;
      this.search();
    },
  },
};
</script>
