<template>
  <div :style="outer_container_style" class="sl-box-styling">
    <!-- Header -->
    <div class="sl-flex-justify-content-space-btwn sl-ptb-10px sl-mb-10px">
      <div class="sl-h2-roboto">New Project Order Form</div>
      <div>
        <!-- renders the "X" btn as well as the "close" modal (Do not show on last page) -->
        <OrderCancelationModal v-if="current_page < 5" />
      </div>
    </div>

    <!-- Review Header - Only shows on Last Page -->
    <div v-if="current_page === 5" class="sl-sub-header-blue-10 sl-mb-10px">
      Review and submit order
    </div>

    <!-- Outer Container -->
    <div class="sl-flex-only">
      <!-- Left Container - Inputs -->
      <div :style="left_column_style">
        <!-- page 1 -->
        <div v-if="current_page === 1">
          <Page1 :passed_in_order_form_data="order_form_data"
                 :google_map_data="google_map_data"
                 :dropdown_selections="dropdown_selections"
                 @next_page="next_page" />
        </div>

        <!-- page 2 -->
        <div v-if="current_page === 2">
          <Page2 :passed_in_order_form_data="order_form_data"
                 @next_page="next_page"
                 @previous_page="previous_page"
                 @update_order_form_data="update_order_form_data" />
        </div>

        <!-- page 3 -->
        <div v-if="current_page === 3">
          <Page3 :passed_in_order_form_data="order_form_data"
                 :dropdown_selections="dropdown_selections"
                 @next_page="next_page"
                 @previous_page="previous_page" />
        </div>

        <!-- page 4 -->
        <div v-if="current_page === 4">
          <Page4 :passed_in_order_form_data="order_form_data"
                 :dropdown_selections="dropdown_selections"
                 @next_page="next_page"
                 @previous_page="previous_page"
                 @jump_to_page="jump_to_page" />
        </div>

        <!-- page 5 -->
        <div v-if="current_page === 5">
          <Page5 :passed_in_order_form_data="order_form_data" />
        </div>
      </div>

      <!-- Right Container - Map (page 1 only) -->
      <div v-if="current_page === 1"  class="sl-ml-30px sl-w-100per">
        <div id="map-container" style="width: 100%;height: 600px;"></div>
      </div>

      <!-- Right Container - Summary -->
      <div v-else :style="right_column_style" class="sl-ml-20px sl-pl-20px sl-custom-left-border">
        <!-- Show header "Order Summary" only on pages 1-4 -->
        <div class="sl-mb-20px sl-sub-header-charcoal-light-40" v-if="current_page !== 5">
          <!-- right arrow toggle -->
          <span v-if="order_summary_visibility" class="sl-flex-only">
            Order Summary <menuArrow class="sl-pl-5px sl-cursor-pointer" @click="toggleSummaryVisibility" />
          </span>
          <!-- left arrow toggle -->
          <span v-if="!order_summary_visibility" class="sl-flex-only">
            <menuArrow class="sl-pl-5px sl-cursor-pointer" style="transform: rotate(180deg);" @click="toggleSummaryVisibility" />
          </span>
        </div>

        <OrderSummary v-if="order_summary_visibility"
                      :order_form_data="order_form_data"
                      :current_page="current_page"
                      :order_summary_view="order_summary_view"
                      :add_update_data_modal="add_update_data_modal"
                      :dropdown_selections="dropdown_selections"
                      :no_contact_data="no_contact_data"
                      @show_pop_up="show_pop_up"
                      @previous_page="previous_page" />
      </div>
    </div>
  </div>
  <br>

  <AddUpdateDataModal :passed_in_order_form_data="order_form_data"
                      :add_update_data_modal="add_update_data_modal"
                      :dropdown_selections="dropdown_selections"
                      @hide_pop_up="hide_pop_up"
                      @update_order_form_data="update_order_form_data" />
</template>

<script>
/* eslint max-lines: ["error", {"max": 700, "skipComments": true, "skipBlankLines": true}] */
import axios from 'axios';
import { cloneDeep as _cloneDeep } from 'lodash';
import Page1 from '../../components/project_orders/Page1.vue';
import Page2 from '../../components/project_orders/Page2.vue';
import Page3 from '../../components/project_orders/Page3.vue';
import Page4 from '../../components/project_orders/Page4.vue';
import Page5 from '../../components/project_orders/Page5.vue';
import OrderSummary from '../../components/project_orders/OrderSummary.vue';
import AddUpdateDataModal from '../../components/project_orders/AddUpdateDataModal.vue';
import OrderCancelationModal from '../../components/project_orders/OrderCancelationModal.vue';
import menuArrow from '../../../../assets/images/icons/icon-menu-charcoal-100_16.svg';

export default {
  components: {
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    OrderSummary,
    AddUpdateDataModal,
    OrderCancelationModal,
    menuArrow,
  },

  data() { // eslint-disable-line max-lines-per-function
    return {
      order_summary_visibility: true,
      current_page: 1,
      required_fields_text: 'sl-black-text',
      no_contact_data: '',
      dropdown_selections: {}, // Populated from the API call on page load
      add_update_data_modal: {
        show: false,
        section: '',
      },
      order_summary_view: {
        property_type: false,
        property_location: false,
        loan_details: false,
        invoice_details: false,
        report_addressee_details: false,
        site_contact_and_property_details: false,
        email_cc: false,
        order_notes: false,
        requested_services: false,
        right_column_visible: false,
      },
      google_map_data: {
        api_key: '',
        lat: 39.8097343, // default to USA
        lng: -98.5556199,
        zoom: 4,
      },
      empty_order_form_data: {}, // used to check if the order_form_data has been updated
      order_form_data: {
        property_location: {
          name: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          county: '',
          country: '',
          radon_zone: '',
          seismic_zone: '',
          lat: '',
          lng: '',
          apn: '',
        },
        property_type_data: {
          primary_property_type: '',
          secondary_property_type: '',
          property_development_type: '',
        },
        property_site_details_data: {
          year_built: '',
          num_of_buildings: '',
          num_of_units_rooms_sites: '',
          num_of_tenants: '',
          ground_floor_units: '',
          num_of_floors: '',
          building_sq_ft: '',
          land_acreage: '',
          estimated_construction_budget: '',
          percent_units_to_inspect: '',
        },
        loan_data: {
          client_reference_loan_number: '',
          purpose_of_order: '',
          describe_purpose_of_order: '',
          loan_term: '',
          loan_type: '',
          agency_type: '',
        },
        client_contact_data: {
          full_name: '',
          address: '',
          address_2: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          phone: '',
          email: '',
        },
        report_addressee_data: {
          first_name: '',
          last_name: '',
          title: '',
          address: '',
          address_2: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          email: '',
          company_name: '',
        },
        invoice_data: {
          first_name: '',
          last_name: '',
          company_name: '',
          email: '',
          do_not_contact: '',
          address: '',
          address_2: '',
          city: '',
          state: '',
          zip: '',
          country: '',
        },
        site_contact_data: {
          first_name: '',
          last_name: '',
          phone: '',
          email: '',
          relationship_to_property: '',
          site_visit_request_dates: '',
          do_not_contact: '',
        },
        property_information_contact_data: {
          first_name: '',
          last_name: '',
          phone: '',
          email: '',
          relationship_to_property: '',
          do_not_contact: '',
          intended_use: '',
          intended_users: '',
        },
        service_total: '$ - - - -',
        email_cc_data: '',
        order_notes_data: '',
        requested_services_data: [],
        service_estimates_and_selections_data: [],
        invoice_data_entered: false,
        report_addressee_data_entered: false,
        site_contact_data_entered: false,
        property_information_contact_data_entered: false,
      },
    };
  },

  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    const project_order_id = urlParams.get('project_order_id');

    // before the page loads, make a copy of the empty order_form_data
    this.empty_order_form_data = _cloneDeep(this.order_form_data);

    // This call will get user contact data, and also draft saved data
    await axios.get('/project_orders/load_initial_order_form_data', {
      params: {
        project_order_id,
      },
    })
      .then((response) => {
        // set default "purpose of order" to "financing agency" if user client is a agency client
        if (response.data.is_agency_client) {
          this.order_form_data.loan_data.purpose_of_order = 'Financing Agency';
        }

        // set order_form_data from pending order if it exists
        // set page to page 4 by default, from old order form, set page to page 1
        if (response.data.order_form_data) {
          this.order_form_data = Object.assign(this.order_form_data, response.data.order_form_data);

          if (response.data.coming_from_old_order_form) {
            this.current_page = 1;
          } else {
            this.current_page = 4;
          }
        }

        if (response.data.user_contact === '') {
          this.no_contact_data = 'No contact data found.';
        } else {
          // this will come from the User's associated contact data
          this.order_form_data.client_contact_data.full_name = response.data.user_contact.name;
          this.order_form_data.client_contact_data.address = response.data.user_contact.address;
          this.order_form_data.client_contact_data.address_2 = '';
          this.order_form_data.client_contact_data.city = response.data.user_contact.city;
          this.order_form_data.client_contact_data.state = response.data.user_contact.state;
          this.order_form_data.client_contact_data.zip = response.data.user_contact.zip;
          this.order_form_data.client_contact_data.country = '';
          this.order_form_data.client_contact_data.phone = response.data.user_contact.phone;
          this.order_form_data.client_contact_data.email = response.data.user_contact.email;
        }

        // set the google map api key
        this.google_map_api_key = response.data.google_map_api_key;

        // set dropdown selections
        this.dropdown_selections = response.data.dropdown_selections;
      })
      .catch((error) => {
        alert(error);
      });
  },

  computed: {
    outer_container_style() {
      if (this.current_page === 1 || this.current_page === 5) {
        return 'max-width: 1500px; margin: 0 auto;';
      }

      return 'max-width: 1300px; margin: 0 auto;';
    },

    left_column_style() {
      if (this.current_page === 1) {
        return 'width: 100%; max-width: 600px;';
      } if (this.current_page === 5) {
        return 'width: 50%;';
      } if (!this.order_summary_visibility) {
        return 'width: 96%;';
      }

      return 'width: 70%;';
    },

    right_column_style() {
      if (this.current_page === 5) {
        return 'width: 50%;';
      } if (!this.order_summary_visibility) {
        return 'width: 4%;';
      }

      return 'width: 30%;';
    },
  },

  methods: {
    toggleSummaryVisibility() {
      this.order_summary_visibility = !this.order_summary_visibility;
    },

    // It will update the order_form_data with the new data
    // the new data should have the same structure as the order_form_data
    update_order_form_data(new_data_obj) {
      const new_data = _cloneDeep(new_data_obj);

      // update the order_form_data with the new data
      this.order_form_data = new_data;
    },

    show_pop_up(section) {
      this.add_update_data_modal.show = true;
      this.add_update_data_modal.section = section;
    },

    hide_pop_up() {
      this.add_update_data_modal.show = false;
    },

    // Move to the previous page (go back button)
    previous_page() {
      this.current_page -= 1;
    },

    // Move to the next page (continue button)
    next_page() {
      this.current_page += 1;

      // show the order summary on the last page if it was collapsed
      if (this.current_page === 5) {
        this.order_summary_visibility = true;
      }
    },

    jump_to_page(page_number) {
      this.current_page = page_number;
    },
  },
};
</script>

<style>
  .sl-custom-left-border {
    border-left: 5px solid var(--primary-blue);
  }

  .sl-custom-btn-padding-1 {
    padding: 5px 7%;
  }

  .sl-custom-btn-padding-2 {
    padding: 7px 20%;
  }

  .sl-total-cost-text {
    font-family: BebasNeue;
    font-size: 48px;
    color: var(--primary-blue);
  }

  .sl-red-border {
    border: 1px solid red !important;
  }

  .sl-required-text {
    color: red;
    font-weight: bold;
  }

  .sl-black-text {
    color: black;
  }

  .sl-box-styling input,
  .sl-box-styling select,
  .sl-box-styling textarea {
    box-sizing: border-box;
  }

  .sl-box-styling select:disabled {
    background: var(--light-charcoal);
  }

  .sl-border-bottom-2px-charcoal {
    border-bottom: 2px solid var(--dark-charcoal);
  }

  .sl-sub-header-blue-10 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 14px;
    color: var(--primary-blue);
    background: var(--blue-10);
    padding: 5px 10px 5px 10px;
  }

  .sl-sub-header-charcoal-light-40 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 14px;
    color: var(--dark-charcoal);
    background: var(--charcoal-light-40);
    padding: 5px 10px 5px 10px;
  }

  .sl-input-type-1 {
    width: 100%;
    padding: 8px 7px;
    border: 1px solid var(--medium-charcoal);
  }

  .sl-input-type-1:focus {
    outline: none;
  }

  .sl-input-error {
    border: 1px solid red;
  }

  /* Targets the title above the input */
  .sl-input-title-error > .sl-para-small {
    color: red;
  }

  /* For vue Transition component */
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  /* Icons */
  .sl-x-btn {
    filter: brightness(0) saturate(100%) invert(69%) sepia(0%) saturate(2753%) hue-rotate(216deg) brightness(92%) contrast(89%);
  }
  .sl-x-btn:hover {
    filter: brightness(0) saturate(100%) invert(49%) sepia(72%) saturate(6222%) hue-rotate(351deg) brightness(102%) contrast(88%);
    cursor: pointer;
  }

  .sl-charcoal-btn {
    filter: brightness(0) saturate(100%) invert(69%) sepia(0%) saturate(2753%) hue-rotate(216deg) brightness(92%) contrast(89%);
  }
  .sl-charcoal-btn:hover {
    filter: brightness(0) saturate(100%) invert(16%) sepia(77%) saturate(3078%) hue-rotate(194deg) brightness(98%) contrast(102%);;
    cursor: pointer;
  }
</style>
