<template>
  <div class="sl-flex-column sl-flex-align-items-flex-end">
    <div class="sl-flex-justify-content-end sl-mt-10px">
      <a v-if="mixedUsePropertyTypes.length > 2" class="sl-h-28px">
        <deleteIcon @click="$emit('delete', mixedUsePropertyType.id)"
                    class="figma-icon-delete links-center sl-mr-10px sl-mt-10px"/>
      </a>
      <div class="sl-font-size-12px sl-modified-text sl-w-75px sl-text-align-right sl-mt-10px sl-mr-20px">
        Property Type {{  mixedUsePropertyType.number }}
      </div>
      <select v-model="mixedUsePropertyType.property_type_id"
              class="sl-border-2px-medium-charcoal sl-pl-5px sl-mb-10px sl-h-37px sl-w-300px">
        <option v-for="propertyType in propertyTypes" :value="propertyType.id">{{ propertyType.name }}</option>
      </select>
    </div>

    <div class="sl-flex-justify-content-end sl-border-b-2px-light-charcoal sl-pb-10px sl-mb-20px">
      <div class="sl-font-size-12px sl-modified-text sl-w-75px sl-text-align-right sl-mt-10px sl-mr-10px">
        Percentage
      </div>
      <input v-model="mixedUsePropertyType.percentage" type="text"
             @change="formatValue($event)"
             class="sl-border-2px-medium-charcoal sl-pl-10px
                    sl-pr-10px sl-h-32px sl-mb-10px sl-w-275px"/>
    </div>
  </div>
</template>
<script>
import CloseIcon from '../../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';
import deleteIcon from '../../../../assets/images/icons/Icon-Clear_Charcoal-Dark-100_16.svg';

export default {
  props: ['mixedUsePropertyType', 'mixedUsePropertyTypes', 'propertyTypes'],
  components: {
    CloseIcon,
    deleteIcon,
  },

  methods: {
    // Add percentage sign on field change
    formatValue(e) {
      let { value } = e.target;
      value = value.replace(/[^\d.-]/g, '');
      e.target.value = `${value}%`;
    },
  },
};
</script>
